<template>
  <div class="text-center">
      <v-tooltip top  v-for="(action,index) in filterActions()" :key="index">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          v-bind="attrs"
          v-on="on"
           class="mx-2" @click="genericAction(action.name)" icon small :color="action.color"
        > <v-icon dark>{{action.icon}}</v-icon></v-btn>
      </template>
      <span>{{action.title}}</span>
    </v-tooltip>

     
    
  </div>
</template>

<script>
export default {
  name: "ButtonsActions",
  props:{
    actionsOptions:Array
  },
  data: () => ({
    actions:[  
      {
        title:'URL',
        name:'url',
        icon:'mdi-link',
        color:'blue darken-1'
      }
      ,
        { 
          title:'Apuração',
          name:'report',
          icon:'mdi-page-next',
          color:'cyan darken-1'
        },
      { 
        title:'Eleitores',
        name:'voters',
        icon:'mdi-human-greeting',
        color:'blue darken-1'
      },
      
      { 
        title:'Editar',
        name:'edit',
        icon:'mdi-pencil-box',
        color:'amber'
      },
      { 
        title:'Vizualizar',
        name:'preview',
        icon:'mdi-eye',
        color:'cyan accent-4'
      },
      { 
        title:'Excluir',
        name:'delete',
        icon:'mdi-delete',
        color:'red darken-1'
      }
    ]
  }), 
  methods:{
      filterActions(){
        return this.actions.filter(action => {
            if(this.actionsOptions.indexOf(action.name) > -1) return action
        })
      },
      genericAction(type){
        this.$emit('action', type)
      }
    }
};
</script>
